import { ref } from 'vue'
import { defineStore } from 'pinia'

import useNotificationsStore from '@/stores/notifications'
import apiTranslations from '@/api/translations'
import { type TranslationKeys } from '@/types/translations'
import config from '@/config'

const initialTranslations = {
  casino_slots: 'Casino Slots',
  casino_slots_info: 'Spin reels to match symbols and win prizes.',
  default_error_message: 'Something went wrong',
  deposit_failed_amount_low: 'Minimum amount is {amount}',
  deposit_failed_amount_high: 'Maximum amount is {amount}',
  deposit_failed_notification_info: 'Deposit failed! Please try again.',
  deposit_failed_plugin_disabled: 'Deposit is currently disabled.',
  device_out_of_order_info:
    'The device is currently malfunctioning and will not be operational for a while.',
  general_balance: 'Balance',
  general_cancel: 'Cancel',
  general_inactivity_detected: 'Inactivity detected',
  general_log_in: 'Log in',
  general_log_out: 'Log out',
  general_low_balance: 'Low balance',
  general_out_of_order: 'Out of order',
  general_verify: 'Verify',
  general_welcome: 'Welcome',
  general_yes: 'Yes',
  inactivity_detected_info: 'Terminal will log out in:',
  inactivity_detected_footer_info: 'Press anywhere to cancel!',
  log_in_required_modal_info:
    'This game requires you to be logged in to play it.\nDo you want to log in?',
  log_out_modal_info: 'Are you sure you want to log out?',
  log_out_notification_info: 'You are logged out!',
  low_balance_info:
    'Your balance is low. Please add funds to your account or you will be logged out in:',
  qr_log_in: 'QR Log in',
  qr_log_in_info: 'For log in, use phone camera to scan QR code below',
  session_already_active_notification_info: 'You are already logged in on another terminal!',
  successful_deposit_notification_message: 'Successful deposit!',
  terminal_disabled_notification_info: 'Terminal disabled',
  terminal_duplicate_dongle_id_notification_info: 'Another terminal has same dongleId',
  try_demo_games: 'Try demo games',
  verify_error_otp_expired: 'OTP code expired.\nUse the application to resend your code.',
  verify_error_wrong_otp_code: 'Wrong OTP code',
  verify_inactivity_return_to_home_screen: 'Returning to home screen due to inactivity',
  verify_info: 'Please enter the OTP code for verification'
} as const

const useTranslationsStore = defineStore('translations', () => {
  const notificationsStore = useNotificationsStore()

  const { handleErrorNotification } = notificationsStore

  const translations = ref<Record<TranslationKeys, string>>(initialTranslations)

  const t = (key: TranslationKeys, replacements: Record<string, string | number> = {}) => {
    const translatedValue = translations.value[key] ?? key

    return translatedValue.replace(/{([^{}]*)}/g, (match, key) => {
      return key in replacements ? String(replacements[key]) : match
    })
  }

  const getTranslations = async () => {
    try {
      translations.value = await apiTranslations.getTranslations(config.userLanguage)
    } catch (err) {
      handleErrorNotification(err)
    }
  }

  return {
    translations,
    t,
    getTranslations
  }
})

export { initialTranslations }
export default useTranslationsStore
