export const enum StompSessionMessages {
  SESSION_TERMINATED = 'SESSION_TERMINATED',
  SESSION_INITIATED = 'SESSION_INITIATED',
  DEPOSIT_SUCCESSFUL = 'DEPOSIT_SUCCESSFUL',
  DEPOSIT_FAILED = 'DEPOSIT_FAILED',
  DEPOSIT_FAILED_PLUGIN_DISABLED = 'DEPOSIT_FAILED_PLUGIN_DISABLED',
  DEPOSIT_FAILED_AMOUNT_LOW = 'DEPOSIT_FAILED_AMOUNT_LOW',
  DEPOSIT_FAILED_AMOUNT_HIGH = 'DEPOSIT_FAILED_AMOUNT_HIGH'
}

export const enum StompTerminalMessages {
  TERMINAL_ACTIVATED = 'TERMINAL_ACTIVATED',
  TERMINAL_DEACTIVATED = 'TERMINAL_DEACTIVATED'
}

interface BaseMessage {
  timestamp: string
}

interface BaseTerminalMessage extends BaseMessage {
  terminalId: string
}

interface BaseSessionMessage extends BaseMessage {
  sessionId: string
}

interface TerminalMessageBody {
  type: StompTerminalMessages
  message: BaseTerminalMessage
}

interface SessionMessageBody {
  type:
    | StompSessionMessages.SESSION_TERMINATED
    | StompSessionMessages.SESSION_INITIATED
    | StompSessionMessages.DEPOSIT_FAILED_PLUGIN_DISABLED
  message: BaseSessionMessage
}

interface DepositMessageBody {
  type:
    | StompSessionMessages.DEPOSIT_SUCCESSFUL
    | StompSessionMessages.DEPOSIT_FAILED
    | StompSessionMessages.DEPOSIT_FAILED_AMOUNT_HIGH
    | StompSessionMessages.DEPOSIT_FAILED_AMOUNT_LOW
  message: BaseSessionMessage & {
    amount: number
  }
}

export type StompSessionMessageData = SessionMessageBody | DepositMessageBody
export type StompTerminalMessageData = TerminalMessageBody
